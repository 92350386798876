<template>
  <div class="send-record">
    <div>
      <timetable-title>本讲发放记录</timetable-title>
      <close-icon/>
    </div>


    <div class="send_table" v-if="tableData.length>0">
      <a-table
          :columns="columns"
          :data-source="tableData"
          :rowClassName="rowClassName"
          :pagination="false"
          :scroll="{y: '70vh'}"
          :locale="{}"
          row-key="integralRecordId"
      >
        <template #opt="{record}">
          <a-popconfirm
              title="确定要删除吗？"
              @confirm="onDelete(record)"
          >
            <a style="color: inherit">删除</a>
          </a-popconfirm>
        </template>
      </a-table>

      <a-pagination
        show-size-changer
        show-quick-jumper
        :total="total"
        :style="{color: '#FFF'}"
        :showTotal="total => `共${total}条`"
        @change="onPageChange"
        @show-size-change="onSizeChange"
      />
    </div>

    <div v-else style="margin-top: 200px">
      <empty-no-lesson
        :img-path="require('@/assets/image/timetable/pic_nodate@2x.png')"
      >
        <span style="color: #FFF">暂无发放记录哦~</span>
      </empty-no-lesson>
    </div>


  </div>
</template>

<script>
import {sendRecordBill} from "@/views/timetable/myTimetable/SendRecord/SendRecord";
import TimetableTitle from "@/components/timetable/TimetableTitle";
import CloseIcon from "@/components/system/CloseIcon";
import {Popconfirm, Table, Pagination} from "ant-design-vue";
import EmptyNoLesson from "@/components/timetable/EmptyNoLesson";
import {onMounted} from "vue";

export default {
  name: "SendRecord",
  components: {
    EmptyNoLesson,
    CloseIcon,
    TimetableTitle,
    ATable: Table,
    APopconfirm: Popconfirm,
    APagination:Pagination,
  },
  data() {
    return {}
  },
  setup(){
    const {
      refData,
      staticState,
      onDelete,
      rowClassName,
      onPageChange,
      onSizeChange,
      init,
    } = sendRecordBill();

    onMounted(async () => {
      await init();
    })

    return {
      ...refData,
      ...staticState,
      onDelete,
      rowClassName,
      onPageChange,
      onSizeChange,
      init,
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="less" scoped>
.send-record {
  .send_table{
    padding: 0 30px;
    margin-top: 10px;
    :deep(.ant-table){
      td { white-space: nowrap; }
      .table-f9{
        color: #FF9000!important;
      }
      .ant-table-header{
        background-color: #FF9900;
      }
      .ant-table-thead{
        .ant-table-align-center{
          background-color: #FF9900;
          color: #FFF;
        }
      }
      .ant-table-body{
        &::-webkit-scrollbar{
          margin-right: 3px;
          width: 5px;
          background: rgba(255, 255, 255, 0);
        }
        &::-webkit-scrollbar-thumb{
          margin-right: 3px;
          background: rgba(0, 0, 0, .1);
          border-radius: 10px;
        }
      }
    }
    .ant-pagination{
      text-align: right;
      margin-top: 20px;
    }
  }
}
</style>
